.fot1 {
  bottom: 0;
  width: 100%;
}
.bg-purple {
  background-color: #6f42c1;
}
.h1rounded {
  border-radius: 0.65rem !important;
  position: relative;
}
html {
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.a-class:hover {
  color: #0d6efd;
  font-size: 0.95em;
  text-decoration: none;
}
.a1-class {
  color: #6c757d;
  display: inline;
  position: relative;
  margin-block: 2px;
  font-size: medium;
  text-decoration: none;
}
.a1-class::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: #18272f;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
}
.a1-class:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
#h1-banr {
  box-shadow: 0 1.125rem 0.25rem rgb(0 0 0 / 12%) !important;
  opacity: 5;
}
.question-container1 {
  border-radius: 0.65rem !important;
  position: relative;
}
.question-container2 {
}
.mcq_question_no {
  font-weight: bold;
}
.main-question-content {
}
.question-side-component {
}
.answer-button {
  padding: 0.25rem 0.9rem;
  font-size: 0.9rem;
  border-radius: 0.8rem;
}
.answer-text {
  font-weight: bold;
}
.text-justify {
  text-align: justify;
}
.mcq_question {
  color: #0d6efd;
  font-weight: bold;
}
.sidebar {
  height: 100%;
  min-height: 400px;
  color: #fff;
  overflow: hidden;
  top: 12%;
}
.main-section {
}
#progress-bar {
  --scrollAmount: 0%;
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  width: var(--scrollAmount);
  height: 4px;
  position: fixed;
  top: 0;
}
.containerpaginati {
  flex-wrap: wrap;
}
.page-link {
  font-size: 20px;
}
.pagitem {
}
.modal-header {
  padding: 0.8rem 0.7rem;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.veron {
  font-size: 12px;
}
marquee {
  -webkit-animation: caption 50s linear 0s infinite;
  padding: 5px 0;
  -webkit-transition: background-color 350ms;
  -moz-transition: background-color 350ms;
  transition: background-color 350ms;
  overflow: hidden;
  overflow-x: -webkit-marquee;
  -webkit-marquee-direction: right;
  -webkit-marquee-style: scroll;
  -webkit-marquee-speed: normal;
  -webkit-marquee-increment: small;
  -webkit-marquee-repetition: 5;
  overflow-x: marquee-line;
  marquee-direction: forward;
  marquee-style: loop;
  marquee-speed: slow;
  marquee-play-count: 5;
  color: #28282a;
}
.anchorjs-link {
  display: inline-block;
  text-align: right !important;
  font-style: italic;
  font-weight: 500;
  font-size: 1.2em;
  color: #fa042da8;
}
.anchorjs-link:hover {
  color: #fa042da8;
}
.anchorjs-link:hover:before {
  content: "link";
  color: #fa042da8;
}
*:hover > .anchorjs-link {
  transition: color 0.25s linear;
}
